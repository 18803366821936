<div class="breadcrumbs-block">
  <div *ngFor="let keys of items; last as isLast; first as isFirst" class="breadcrumb" [ngClass]="keys.active ? 'breadcrumb-color' : ''">
    <div>
      <a>
        {{ keys.label }}
      </a>
    </div>
    <div>
      <i class="pi pi-angle-right" *ngIf="!isLast"></i>
    </div>
  </div>
</div>
