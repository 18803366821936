<app-header></app-header>
<div class="py-4 md:px-4 px-1 flex flex-column application-body">
  <app-wealth-breadcrumb [items]="breadcrumbData"></app-wealth-breadcrumb>
  <div class="lg:py-3 content-block">
    <div class="content" [ngClass]="{ 'full-height': selectedPage === 'mobile' || hideFooter }">
      <router-outlet></router-outlet>
    </div>
    <div class="footer-block pl-3" *ngIf="selectedPage !== 'mobile' && !hideFooter">
      <div>
        <div *ngIf="selectedPage === 'account'" class="px-2">
          <div class="m-2 mt-4">
            <p-checkbox
              inputId="declaration"
              [binary]="true"
              [value]="false"
              (onChange)="checkBoxEntFn($event)"
              [(ngModel)]="checkboxEnt.declaration"
            ></p-checkbox>
            <label for="declaration">I agree to <span (click)="showDialog()" class="declare">declaration and undertaking</span></label>
          </div>
          <!-- <div class="m-2">
            <p-checkbox
              name="bse-mutual"
              [binary]="true"
              [value]="false"
              [(ngModel)]="checkboxEnt.bseMutual"
              (onChange)="checkBoxEntFn($event)"
            ></p-checkbox>
            <label for="bse-mutual"> I agree to creating an account on BSE Star for mutual fund transactions </label>
          </div> -->
        </div>
        <div *ngIf="selectedPage === 'personal-details' && agedeclaration" class="px-2">
          <div class="m-4">
            <p-checkbox
              inputId="age"
              [binary]="true"
              [value]="true"
              (onChange)="checkBoxEntFn($event)"
              [(ngModel)]="checkboxEnt.age"
            ></p-checkbox>
            <label for="declaration">I accept <span (click)="showDialog()" class="declare">age declaration.</span></label>
          </div>
        </div>
        <div *ngIf="selectedPage === 'kyc' && MarketDeclaration" class="px-2">
          <div class="m-4">
            <p-checkbox
              inputId="market"
              [binary]="true"
              [value]="MarketDeclarationValue"
              (onChange)="checkBoxEntFn($event)"
              [(ngModel)]="checkboxEnt.market"
            ></p-checkbox>
            <label for="declaration">I agree to <span (click)="showDialog()" class="declare">declaration and undertaking</span></label>
          </div>
        </div>
      </div>
      <div class="bottom-btn" *ngIf="!digiLockerFailed">
        <button
          *ngFor="let btn of bottomButton"
          class="btn-style"
          [ngClass]="{ disabled: btn.isDisabled }"
          [disabled]="btn.isDisabled"
          (click)="btnClicked(btn)"
          [hidden]="!btn.visible"
        >
          {{ btn.label }}
        </button>
      </div>
    </div>
  </div>
</div>
