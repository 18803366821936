import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { bottomButton, breadcrumbConst } from '../../constants/layout.const';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  layoutVariable: any = {
    bottomButton: [...bottomButton],
    breadcrumb: [...breadcrumbConst],
    selectedPage: '',
  };

  layoutVariableSub: any = new BehaviorSubject(this.layoutVariable);
  clickEvent: any = new BehaviorSubject({});
  routingChange: any = new BehaviorSubject('');
  private isAgeDeclarationValue: any = new BehaviorSubject(true);
  private isAgeDeclaration: any = new BehaviorSubject(false);
  private isMarketDeclaration: any = new BehaviorSubject(false);
  private isBseMf: any = new BehaviorSubject(false);
  private isMarketDeclarationValue: any = new BehaviorSubject(false);
  private isDigiLockerFailed: any = new BehaviorSubject(false);
  AgeDeclarationValue = this.isAgeDeclaration.asObservable();
  isAgeDeclarationValueset = this.isAgeDeclarationValue.asObservable();
  isBseMfSet = this.isBseMf.asObservable();
  marketDeclaration = this.isMarketDeclaration.asObservable();
  marketDeclarationValue = this.isMarketDeclarationValue.asObservable();
  DigiLockerFailed = this.isDigiLockerFailed.asObservable();

  constructor() {
    this.resetLayoutVariable();
  }

  addBottomButton(btn: any) {
    const buttonData: any = this.layoutVariable;
    buttonData.bottomButton = [...bottomButton, ...btn];
    this.setLayoutVariable(buttonData);
  }

  updateBottomButton(update: any) {
    const buttonData: any = this.layoutVariable;
    buttonData.bottomButton = bottomButton.map((val: any) => (val.key === update.key ? update : val));
    this.setLayoutVariable(buttonData);
  }

  resetBreadCrumbData(breadcrumb: any) {
    const layoutVar = this.layoutVariable;
    layoutVar.breadcrumb = breadcrumb;
    this.setLayoutVariable(layoutVar);
  }

  updateBreadcrumb(update: any) {
    this.layoutVariable.selectedPage = update;
    const breadcrumbData = this.layoutVariable;
    let found = false;
    breadcrumbData.breadcrumb = breadcrumbConst.map((res: any) => {
      if (!found) {
        res.active = true;
        found = res.key === update ? true : found;
      }
      return res;
    });
  }

  resetLayoutVariable() {
    const property = {
      bottomButton: [...bottomButton],
      breadcrumb: [...breadcrumbConst],
      selectedPage: this.layoutVariable.selectedPage,
    };
    this.setLayoutVariable(property);
    this.clickEvent.next({});
  }

  setLayoutVariable(property: any) {
    this.layoutVariable = property;
    this.layoutVariableSub.next(property);
  }

  setClickEvent(property: any) {
    this.clickEvent.next(property);
  }

  changePage(page: any) {
    this.routingChange.next(page);
  }

  getAgeDeclaration(value: boolean, defValue: any) {
    this.isAgeDeclaration.next(value);
    this.isAgeDeclarationValue.next(defValue);
  }

  getBseMf(value: boolean) {
    this.isBseMf.next(value);
  }

  getMarketDeclaration(value: boolean) {
    this.isMarketDeclaration.next(value);
  }

  getMarketDeclarationValue(value: boolean) {
    this.isMarketDeclarationValue.next(value);
  }

  getDigiLockerFailed(value: boolean) {
    this.isDigiLockerFailed.next(value);
  }
}
