// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth0: {
    callbackURL: 'https://dev-bok5-5k4.us.auth0.com/login/callback',
    clientId: 'mStr5daTCVchBEL0Z44GJo1VQQ6lkW6R',
    domain: 'dev-bok5-5k4.us.auth0.com',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/login`,
    },
    cacheLocation: 'localstorage',
  },
  api: {
    url: 'https://api-onboarding.nprod.incredwealth.com',
    incred: 'https://api2.incred.com',
    digiLockerUrl:
      'https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?client_id=ESA29931B2&response_type=code&redirect_uri=https://www.incredwealth.com&state=A00002',
  },
  digio: 'https://ext.digio.in/sdk/v11/digio.js',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
