import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare let document: any;

@Injectable({
  providedIn: 'root',
})
export class LoadScriptService {
  scriptMapping: any = {
    auth0: [
      {
        src: 'https://cdn.auth0.com/js/auth0-spa-js/2.0/auth0-spa-js.production.js',
        loaded: false,
        status: 0,
        type: 'script',
      },
    ],
    googlemapAPI: [
      {
        src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAeNB4RWMp0atS5CSqv1GbKUkBldE6TROM&libraries=drawing,places,geometry',
        loaded: false,
        status: 0,
        type: 'script',
      },
    ],
    digio: [
      {
        src: environment.digio,
        loaded: false,
        status: 0,
        type: 'script',
        data: {
          'digio-logo': 'https://www.incredwealth.com/img/c-logo.png',
        },
      },
    ],
  };

  scripts: any = {};

  constructor() {}

  load(scriptName: any) {
    const promises: any[] = [];
    if (this.scriptMapping[scriptName]) {
      this.scriptMapping[scriptName].forEach((script: any) => {
        if (script.status === 0 && !script.loaded) {
          promises.push(this.loadScript(script));
        } else {
          promises.push(this.loadedScript(script));
        }
      });
    }
    return Promise.all(promises);
  }

  loadedScript(scripts: any) {
    return new Promise((resolve, reject) => {
      resolve({
        script: scripts.src,
        loaded: scripts.loaded,
        status: 'Loaded',
      });
    });
  }

  loadScript(scripts: any) {
    scripts.status = 1;
    return new Promise((resolve, reject) => {
      if (!scripts.loaded) {
        let script = document.createElement('script');
        if (scripts.type === 'script') {
          script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = scripts.src;
          if (script.data) {
            const dataTag = Object.keys(script.data);

            dataTag.forEach((tag: any) => {
              script.setAttribute(`data-${tag}`, script.data[tag]);
            });
          }
        } else {
          script = document.createElement('link');
          script.type = 'text/css';
          script.rel = 'stylesheet';
          script.href = scripts.src;
        }
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              scripts.loaded = true;
              resolve({
                script: scripts.src,
                loaded: true,
                status: 'Loaded',
              });
            }
          };
        } else {
          // Others
          script.onload = () => {
            scripts.loaded = true;
            resolve({
              script: scripts.src,
              loaded: true,
              status: 'Loaded',
            });
          };
        }
        script.onerror = (error: any) => {
          scripts.status = 0;
          resolve({ script: scripts.src, loaded: false, status: 'failed' });
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({
          script: scripts.src,
          loaded: true,
          status: 'Already Loaded',
        });
      }
    });
  }
}
