import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiURL } from '../../constants/api.const';

@Injectable({
  providedIn: 'root',
})
export class OpsService {
  label: string | undefined;

  constructor(private http: HttpClient) {}
  header = new HttpHeaders({
    'Content-Type': 'multipart/form-data',
  });
  getOpsUsers(pageSize: any, pageNumber: any, sortName: any, sort: any, filter: any, applicationStatus: any) {
    return this.http.get(apiURL.getops(pageSize, pageNumber, sortName, sort, filter, applicationStatus));
  }

  getGroupNames(rmEmpId: any) {
    return this.http.get(apiURL.groupNames(rmEmpId));
  }
  getRMlist() {
    return this.http.get(apiURL.RMuser);
  }
  getSrmList(id: any) {
    return this.http.get(apiURL.SRMuser(id));
  }

  postRejectApprove(data: any, customerId: string) {
    return this.http.post(apiURL.opsRejectApprove(customerId), data, { headers: this.header });
  }

  getDocumentImage(customerId: string, docName: string, docType: string, action: any) {
    return this.http.get(apiURL.downloadViewFile(customerId, docName, docType, action), { headers: this.header });
  }
  getSingleUser(id: any) {
    return this.http.get(apiURL.singleCustomer(id));
  }

  getUserDetails() {
    return this.http.get(apiURL.userDetails());
  }

  getPans(onboardingStatus: any, type: any, value: any) {
    return this.http.get(apiURL.panDetails(onboardingStatus, type, value));
  }
  saveOpsData(data: any, custmerId: any) {
    return this.http.post(apiURL.saveOpsData(custmerId), data);
  }
  docDownload(custmerId: any, docType: any, type: any, action: any, appStatus: any) {
    return appStatus === 'APPLICATION_COMPLETED'
      ? this.http.get(apiURL.DownloadDoc(custmerId, docType, type, action))
      : this.http.get(apiURL.DownloadDocBeforeCustomerComplete(custmerId, docType, type, action));
  }
  consentOPS(custmerId: any, data: any) {
    return this.http.post(apiURL.consentOps(custmerId), data);
  }

  consentSRM(custmerId: any, data: any) {
    return this.http.post(apiURL.consentSrm(custmerId), data);
  }

  customerEmailConsent(custmerId: any) {
    return this.http.post(apiURL.customerEmailConsent(custmerId), null);
  }

  applicationStatus() {
    return this.http.get(apiURL.applicationStatus);
  }

  uploadSignedDocument(custmerId: any, data: any) {
    return this.http.post(apiURL.UploadSignedDocument(custmerId), data, { headers: this.header });
  }

  downloadPartiallySignedDoc(customerId: any, documentId: any, viewOrDownload: any) {
    return this.http.get(apiURL.DownloadPartialySignedDocument(customerId, documentId, viewOrDownload));
  }

  DownloadOpsDocs(filter: any) {
    return this.http.get(apiURL.DownloadOpsDocs(filter));
  }
}
