import { environment } from 'src/environments/environment';

export const apiURL = {
  NSDL: (cid: any) => `${environment.api.url}/v1/customers/${cid}/pan/verify`,
  emailOTP: `${environment.api.url}/v1/auth/otp/send/email`,
  vedrifyEmailOTP: `${environment.api.url}/v1/auth/otp/verify/email`,

  generateOTP: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/otp/send/mobile`,

  verifymobOTP: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/otp/verify/mobile`,

  resendMobOTP: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/otp/resend/mobile`,
  resendEmailOTP: `${environment.api.url}/v1/auth/otp/resend/email`,
  getUserDetaiis: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/pan-details`,
  selfieUpload: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/document`,
  uploadDocument: (custmerId: any) => `${environment.api.url}/v1/customers/${custmerId}/kyc/document`,
  // uploadDocument: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/document`,
  uploadKYCDocument: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/document`,
  getops: (pageSize: number, pageNumber: number, sortName: string, sort: string, filter: string, applicationStatus: string) =>
    `${environment.api.url}/v1/customers?` +
    `${applicationStatus !== undefined && applicationStatus !== null ? `applicationStatus=${applicationStatus}&` : ''}` +
    `${pageSize !== undefined && pageSize !== null ? `pageSize=${pageSize}&` : ''}` +
    `${pageNumber !== undefined && pageNumber !== null ? `pageNumber=${pageNumber}&` : ''}` +
    `${sortName !== undefined && sortName !== null ? `sortCol=${sortName}&` : ''}` +
    `${sort !== undefined && sort !== null ? `sortType=${sort}&` : ''}` +
    `${filter !== undefined && filter !== null ? `filter=${filter}&` : ''}`.slice(0, -1),
  // getops: (pageSize: any, pageNumber: any, sortName: any, sort: any, filter: any) => `${environment.api.url}/v1/customers?onboardingStatus="COMPLETED"&applicationStatus="APPROVED"&pageSize=${pageSize}&pageNumber=${pageNumber}&sortCol=${sortName}&sortType=${sort}`,
  userBankDetails: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/bank-details`,
  aadharDetails: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/aadhar-details`,

  groupNames: (rmId: any) =>
    rmId === undefined ? `${environment.api.url}/v1/user/group-names` : `${environment.api.url}/v1/user/group-names?rmEmpId=${rmId}`,
  RMuser: `${environment.api.url}/v1/user/regional-managers`,
  SRMuser: (id: any) => `${environment.api.url}/v1/user/service-regional-manager?rmEmpId=${id}`,
  bankList: `${environment.api.url}/v1/shared/bank-names`,
  customerDetails: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/details`,
  // opsRejectApprove: `${environment.api.url}/v1/customer/${sessionStorage.getItem('userRole')}/application-status`
  singleCustomer: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/details`,
  applicationDetails: (applicationId: any) => `${environment.api.url}/v1/customers/applications/${applicationId}`,

  patchCustomerDetails: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/details`,
  opsRejectApprove: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application-status`,
  retriveFile: (customerId: any, docName: any, docType: any) =>
    `${environment.api.url}/v1/customers/${customerId}/kyc/document?docName=${docName}&docType=${docType}`,
  downloadViewFile: (customerId: any, docName: any, docType: any, action: any) =>
    `${environment.api.url}/v1/customers/${customerId}/kyc/document?docName=${docName}&docType=${docType}&action=${action}`,

  userDetails: () => `${environment.api.url}/v1/user/details`,

  pennyTest: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/bank-details/penny-test`,

  getDigiLockerHtml: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/kyc/digilocker/html`,

  panDetails: (onboardingStatus: any, type: any, value: any) =>
    value
      ? `${environment.api.url}/v1/customers/filter?filterType=${type}&applicationStatus=${onboardingStatus}&search=${value}`
      : `${environment.api.url}/v1/customers/filter?filterType=${type}&applicationStatus=${onboardingStatus}`,

  digiLockerRedirect: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/kyc/digilocker/verify`,

  completeStatus: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/kyc-status`,
  getCountryCode: `${environment.api.url}/v1/shared/country-codes`,

  investorRisk: `${environment.api.url}/v1/shared/profiler/questionnaire`,

  masters: (data: string) => `${environment.api.url}/v1/shared/master-data?${data}`,
  kycFatca: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/kyc/fatca`,

  questionnaire: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/profiler/questionnaire`,
  pincode: (pincode: string) => `${environment.api.url}/v1/shared/pincode/${pincode}`,
  riskProfile: `${environment.api.url}/v1/shared/profiler/rating`,

  sendRatingData: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/profiler/rating`,

  nomiNee: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/nomination`,
  updateNomiNee: (customerId: string, nominationID: any) => `${environment.api.url}/v1/customers/${customerId}/nomination/${nominationID}`,
  proceedNomiNee: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/application/submit`,
  saveOpsData: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/assign`,
  DownloadDoc: (customerId: any, docType: any, type: any, action: any) =>
    `${environment.api.url}/v1/customers/${customerId}/application/documents?documentType=${docType}&exportType=${type}&action=${action}`,
  DownloadDocBeforeCustomerComplete: (customerId: any, docType: any, type: any, action: any) =>
    `${
      environment.api.url
    }/v1/customers/${customerId}/application/documents/preview?documentType=${docType}&exportType=${type}&action=${action}&exclude[]=${'SIGNATURE_DOCUMENT'}`,
  consentOps: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/consent/OPS`,
  consentSrm: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/consent/SRM`,

  applicationS: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/assign`,
  customerEmailConsent: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/consent/url`,

  //customer consent api's
  verifyCustomerToken: (customerId: string, token: string) =>
    `${environment.api.url}/v1/auth/consent/application/${customerId}/verify?token=${token}`,
  sendEmailCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/email/otp`,
  sendMobileCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/mobile/otp`,
  verifyEmailCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/verify/email/otp`,
  verifyMobileCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/verify/mobile/otp`,
  resendEmailCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/resend/email/otp`,
  resendMobileCustOtp: (customerId: string) => `${environment.api.url}/v1/auth/consent/${customerId}/resend/mobile/otp`,
  viewPdfDocument: (customerId: string) =>
    `${environment.api.url}/v1/customers/${customerId}/application/documents/preview?documentType=ACCOUNT_OPENING_FORM&exportType=PDF`,
  approveOrRejectCustomer: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/application/consent/CUSTOMER`,

  applicationStatus: `${environment.api.url}/v1/customers/onboarding/tasks`,
  verifyToken: (customerId: string) => `${environment.api.url}/v1/auth/consent/application/${customerId}/verify`,
  resendOtpOnWhatsapp: (customerID: any) => `${environment.api.url}/v1/customers/${customerID}/otp/send/whatsapp`,

  bondMarket: (customerId: string) => `${environment.api.url}/v1/customers/${customerId}/bond-market`,
  multiAcc: () => `${environment.api.url}/v1/customers/applications`,
  multiAccKycEmail: (customerId: any, accHolderId: any) => `${environment.api.url}/v1/customers/${customerId}/accounts/${accHolderId}`,
  accountHolder: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/accounts/submit`,
  DigioEsign: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/consent/aadhaar/esign`,
  DigioApplicatonStatus: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/application-status`,
  ModifyPanDetails: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/modify/pan-details`,
  UploadSignedDocument: (customerId: any) => `${environment.api.url}/v1/customers/${customerId}/application/documents/signed`,
  DownloadPartialySignedDocument: (customerId: any, documentId: any, viewOrDownload: any) =>
    `${environment.api.url}/v1/customers/${customerId}/application/documents/digio/${documentId}?action=${viewOrDownload}`,
  DownloadOpsDocs: (filter: any) => `${environment.api.url}/v1/customers/export?filter=${filter}`,
};

export const digiLockerUrl = environment?.api.digiLockerUrl;
