import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WelthCustmerService } from '../../services/login-verification/login-verification.service';
import { OpsService } from '../../services/ops-check/ops.service';
import { UserInfoService } from '../../services/user-info/user-info.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userName: any;
  user: any = {};
  logout: boolean = false;
  Appid: any;
  status: any;
  officeSection: boolean = false;
  pathArray = window.location.pathname;
  customerId: any;
  private onDestroy$: Subject<void> = new Subject<void>();

  applicationStatus: any = {
    APPLICATION_INITIATED: 'Pending Submission', // customer has been created
    APPLICATION_SUBMITTED: 'Submitted', // all customer info is submitted
    APPLICATION_READY_FOR_APPROVAL: 'Ready for Approval', // RM/SRM accepted the application
    APPLICATION_APPROVED: 'Ops Approved', // ops approved/accepted the application
    APPLICATION_COMPLETED: 'Customer Approved', // customer approved the form
    APPLICATION_OPS_REJECTED: 'Ops Rejected', // application rejected by OPS
    APPLICATION_CUSTOMER_REJECTED: 'Customer Rejected', // application rejected by CUSTOMER
    APPLICATION_AADHAAR_ESIGNED: 'Customer sign pending',
  };

  constructor(
    private router: Router,
    private wealthService: WelthCustmerService,
    private opsService: OpsService,
    private userInfoService: UserInfoService,
    private toasterService: ToasterService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.Appid = params['id'];
      params['id'] ? (this.customerId = this.wealthService.getCustomerId()) : '';
      params['id'] && this.getApplications();
    });
    const cid: any = localStorage.getItem('customerId');
    // this.getCustomerDetails(cid);
    this.getUserDetails();
    // this.customerId ? this.getoneCustomerDetails(this.customerId) : '';
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  // async getoneCustomerDetails(cid: string) {
  //   const customer: any = await this.wealthService.customerDetails(cid).toPromise();
  //   this.Appid = customer.response.APPLICATION_ID;
  //   this.status = this.applicationStatus[customer.response.APPLICATION_STATUS];
  // }

  getApplications() {
    this.wealthService
      .getApplicationDetails(this.Appid)
      .toPromise()
      .then((response: any) => {
        response.response.sort((a: any, b: any) => a.ROLE_TYPE.localeCompare(b.ROLE_TYPE));
        this.Appid = response?.response[0]?.APPLICATION_ID;
        this.status = this.applicationStatus[response?.response[0]?.APPLICATION_STATUS];
      });
  }

  // async getCustomerDetails(cid: string) {
  //   try {
  //     if (this.router.url !== '/customer/login' && this.router.url.split('?')[0] !== '/ops-verification') {
  //       const customer: any = await this.wealthService.customerDetails(cid).toPromise();
  //       // this.user = {
  //       //   userName: customer?.response.CUSTOMER_NAME,
  //       //   email: customer?.response.CUSTOMER_EMAIL_ID,
  //       //   phoneNumber: customer?.response.CUSTOMER_PHONE_NUMBER,
  //       // };
  //       // this.Appid = customer.response.APPLICATION_ID;
  //       // this.status = this.applicationStatus[customer.response.APPLICATION_STATUS];
  //     }
  //   } catch (e: any) {
  //     if (e.status == false) {
  //       this.userInfoService.logout();
  //       this.router.navigate(['/customer/login']);
  //     }
  //   }
  // }

  async getUserDetails() {
    if (this.router.url !== '/customer/login' && this.router.url.split('?')[0] !== '/ops-verification') {
      try {
        const customer: any = await this.opsService.getUserDetails().toPromise();
        this.user = {
          userName: customer?.response.USER_NAME,
          email: customer?.response.USER_EMAIL_ID,
          phoneNumber: '',
        };
      } catch (e: any) {
        if (e.status == false) {
          this.userInfoService.logout();
          this.router.navigate(['/login']);
        }
      }
    }
  }
  logoutOp() {
    let userRole = this.userInfoService.getUserRole();
    let redirection = userRole == 'OPS' || userRole == 'RM' || userRole == 'SRM' ? '/login?logout=true' : '/customer/login';
    this.router.navigateByUrl(redirection);
    this.userInfoService.logout();
    if (redirection === '/customer/login') {
      window.location.reload();
    }
  }

  showLogout() {
    if (this.router.url !== '/customer/login' && this.router.url.split('?')[0] !== '/ops-verification') this.logout = true;
    else this.logout = false;
  }
  copyId() {
    const inputID = document.createElement('input');
    inputID.value = this.Appid;
    document.body.appendChild(inputID);
    inputID.select();
    document.execCommand('copy');
    document.body.removeChild(inputID);

    this.toasterService.showToaster({
      severity: 'info',
      summary: 'info',
      detail: 'Application Id copied successfully',
    });
  }
}
