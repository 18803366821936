import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterService } from './toaster/toaster.service';
import { HttpClientModule } from '@angular/common/http';
import { LoadScriptService } from './load-script/load-script.service';
import { LoaderService } from './loader/loader.service';
import { UserInfoService } from './user-info/user-info.service';
import { WealthService } from './wealth/wealth.service';
import { UtilityService } from './utlity/utility.service';
import { LayoutService } from './layout/layout.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [ToasterService, LoadScriptService, LoaderService, UserInfoService, WealthService, UtilityService, LayoutService],
})
export class ServicesModule {}
