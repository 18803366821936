import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { LayoutService } from 'src/app/shared/services/layout/layout.service';
import { UserInfoService } from 'src/app/shared/services/user-info/user-info.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private layoutService: LayoutService,
    private userInfoService: UserInfoService,
    private route: ActivatedRoute
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.layoutService.resetLayoutVariable();
    const snapshotData = { ...next.data, ...next.children[0]?.data };
    const data = snapshotData;
    const redirection = data.opsScreen || false ? '/login' : '/customer/login';
    if (this.userInfoService.getToken() !== '') {
      this.layoutService.changePage(data || {});
      return true;
    } else if (state.url !== redirection) {
      this.router.navigateByUrl(redirection);
      return true;
    } else {
      return true;
    }
  }
}
