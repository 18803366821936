import { HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardModule } from './core/guards/auth-guard.module';
import { InterceptorModule } from './core/interceptors/interceptor.module';
import { HeaderComponent } from './shared/component/header/header.component';
import { LayoutComponent } from './shared/component/layout/layout.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ServicesModule } from './shared/services/services.module';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { BreadcrumbComponent } from './shared/component/breadcrumb/breadcrumb.component';
import { CardModule } from 'primeng/card';
import { OpsLayoutComponent } from './shared/component/ops-layout/ops-layout.component';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [AppComponent, HeaderComponent, LayoutComponent, LoaderComponent, BreadcrumbComponent, OpsLayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InterceptorModule,
    AuthGuardModule,
    HttpClientModule,
    ServicesModule,
    ToastModule,
    CardModule,
    CheckboxModule,
    FormsModule,
    OverlayPanelModule,
  ],
  providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' }, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
