import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiURL } from '../../constants/api.const';

@Injectable({
  providedIn: 'root',
})
export class WelthCustmerService {
  label: string | undefined;
  whatsApp: any;

  constructor(private http: HttpClient) {}

  getSelfie(e: any) {
    return e;
  }

  setSelfie(data: any) {
    this.getSelfie(data);
  }
  getAddress(pin: any) {
    let headers = new Headers();
    // headers.append('Content-Type', 'application/json; charset=utf-8');
    // headers.append('Authorization', "Bearer " + this.token);
    // headers.append('API-TOKEN', this.token);
    return this.http.post('https://api-qa-apex.nprod.incred.com/v2/customer/pincode_details', pin);
  }

  // checkPanNSDL(pan: any) {
  //   return this.http.get('https://api2.incred.com/v2/credit-line/pan/check/' + pan);
  // }

  generateEmailOTP(body: any) {
    return this.http.post(apiURL.emailOTP, body);
  }

  verifyEmailOTP(body: any) {
    return this.http.post(apiURL.vedrifyEmailOTP, body);
  }

  generateOTP(customerId: any, body: any) {
    return this.http.post(apiURL.generateOTP(customerId), body);
  }

  verifyMobOTP(customerId: any, body: any) {
    return this.http.post(apiURL.verifymobOTP(customerId), body);
  }

  resendMobOTP(body: any, cid: string) {
    return this.http.post(apiURL.resendMobOTP(cid), body);
  }

  resendEmailOTP(body: any) {
    return this.http.post(apiURL.resendEmailOTP, body);
  }

  customerDetails(custmerId: any) {
    return this.http.get(apiURL.customerDetails(custmerId));
  }

  getApplicationDetails(applicationId: string) {
    return this.http.get(apiURL.applicationDetails(applicationId), {});
  }

  setCustomerId(id: any) {
    return sessionStorage.setItem('customerId', id);
  }

  getCustomerId() {
    return sessionStorage.getItem('customerId');
  }

  setApplicationId(id: any) {
    return localStorage.setItem('applicationId', id);
  }

  getApplicationId() {
    return localStorage.getItem('applicationId') || sessionStorage.getItem('applicationId');
  }

  setPrimaryCustomerId(id: any) {
    return localStorage.setItem('primaryCustomerId', id);
  }

  getPrimaryCustomerId() {
    return localStorage.getItem('primaryCustomerId') || sessionStorage.getItem('primaryCustomerId');
  }

  getCode() {
    return this.http.get(apiURL.getCountryCode);
  }
  getDiscription() {
    return this.http.get(apiURL.riskProfile);
  }
  psostRating(custmerId: any, data: any) {
    return this.http.post(apiURL.sendRatingData(custmerId), data);
  }
  setWhatappconsent(e: any) {
    this.whatsApp = e;
  }
  getWahtappConsent() {
    return this.whatsApp;
  }
  sendOTPonWhatsapp(custmerId: any, data: any) {
    return this.http.post(apiURL.resendOtpOnWhatsapp(custmerId), data);
  }
  editRating(custmerId: any, data: any) {
    return this.http.patch(apiURL.sendRatingData(custmerId), data);
  }
}
