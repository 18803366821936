<div class="toolbar" role="banner">
  <a href="">
    <img alt="Incred Logo" src="../../../../assets/images/logo.png" class="headerc" />
  </a>
  <div class="spacer"></div>
  <ng-container *ngIf="pathArray === '/customer-info'">
    <div class="application flex align-items-center p-1">
      <div class="id mr-2 flex align-items-center bg app">
        Application ID: {{ Appid }}
        <img
          src="../../../../assets/images/content_copy.png"
          alt=""
          style="width: 17px; margin: 6px"
          class="cursor-pointer"
          (click)="copyId()"
        />
      </div>
      <div class="status bg p-2">Application Status: {{ status }}</div>
    </div>
  </ng-container>
  <div class="user" (click)="showLogout()">
    <!-- <span>Welcome user </span> -->
    <img
      width="40px"
      title="{{ user?.userName }} | {{ user?.email }} | {{ user?.phoneNumber }}"
      alt="Incred Logo"
      src="../../../../assets/images/user.svg"
      class="cursor-pointer headerc"
      (click)="op.toggle($event)"
    />
  </div>
</div>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <div>Name: {{ user?.userName }}</div>
    <!-- <div style="margin-top: 5%; display: flex; justify-content: center">Role: {{ user?.userRole }}</div> -->
    <div style="display: flex; justify-content: center">
      <button (click)="logoutOp()" style="border: none; background-color: #f60; color: #fff; margin-top: 5%; border-radius: 10px; padding: 7px">
        Sign out
      </button>
    </div>
  </ng-template>
</p-overlayPanel>

<!-- <ng-container *ngIf="logout">
  <div class="flex flex-row-reverse mx-4 mt-4">
    <h4>{{ user?.userName }} </h4>
    <button class="p-element p-button p-component" (click)="logoutOp()">Logout</button>
  </div>
</ng-container> -->
