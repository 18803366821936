/**
 * Http Service wrapper for every API calls
 */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UserInfoService } from 'src/app/shared/services/user-info/user-info.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Injectable()
export class Interceptor implements HttpInterceptor, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private userInfoService: UserInfoService, private loaderService: LoaderService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(httpRequest);

    let contentType = httpRequest.headers.get('Content-Type') || 'application/json';
    let token = this.userInfoService.getToken();
    let clone: any = {
      setHeaders: {
        Authorization: 'Bearer ' + token,
        'api-token': this.userInfoService.getToken(),
        'Content-Type': contentType,
      },
    };

    if (contentType === 'multipart/form-data') {
      clone['headers'] = httpRequest.headers.delete('Content-Type');
      delete clone.setHeaders['Content-Type'];
    }

    let req;
    req = httpRequest.clone(clone);

    this.loaderService.showLoader();

    return next.handle(req).pipe(
      tap((data: any) => {
        if (data instanceof HttpResponse) {
          this.loaderService.hideLoader();
        }
        // Future Implementation : Need to do with Response Body
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hideLoader();
        // console.log(error);
        if (error.status === 401 || error.status === 403 || error.message === 'User Not Authorized') {
          this.userInfoService.logout();
        }
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
        } else {
          console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError({ err: error.error, msg: 'Something bad happened; please try again later.' });
      })
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
