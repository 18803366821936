import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiURL, digiLockerUrl } from '../../constants/api.const';

@Injectable({
  providedIn: 'root',
})
export class WealthService {
  constructor(private http: HttpClient) {}
  header = new HttpHeaders({
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  });
  checkPanNSDL(pan: any, cid: any) {
    return this.http.post(apiURL.NSDL(cid), pan);
  }

  getUserDetails(panDetils: any, custmerId: any) {
    let body = {};
    return this.http.post(apiURL.getUserDetaiis(custmerId), panDetils);
  }

  updateUserDetails(panDetils: any, custmerId: any) {
    let body = {};
    return this.http.patch(apiURL.getUserDetaiis(custmerId), panDetils);
  }

  selfieImg(imgData: any, customerId: any) {
    return this.http.post(apiURL.selfieUpload(customerId), imgData, { headers: this.header });
  }
  uploadFile(obj: any, custmerId: any) {
    return this.http.post(apiURL.uploadDocument(custmerId), obj, { headers: this.header });
  }
  uploadKYCDocument(data: any, customerId: any) {
    return this.http.post(apiURL.uploadKYCDocument(customerId), data, { headers: this.header });
  }
  bankDetails(bankData: any, customerId: any) {
    return this.http.post(apiURL.userBankDetails(customerId), bankData);
  }
  aadhaarDetails(aadharDetails: any, cId: any) {
    return this.http.post(apiURL.aadharDetails(cId), aadharDetails);
  }
  bankNameList() {
    return this.http.get(apiURL.bankList);
  }
  getCustomerDetails(customerId: string) {
    return this.http.get(apiURL.customerDetails(customerId), {});
  }
  getApplicationDetails(applicationId: string) {
    return this.http.get(apiURL.applicationDetails(applicationId), {});
  }
  getAllDetails(customerDetails: any) {
    return this.http.post(apiURL.customerDetails(customerDetails.CUSTOMER_ID), customerDetails);
  }
  updateCustomerDetails(customerDetails: any, customerId: string) {
    return this.http.patch(apiURL.customerDetails(customerId), customerDetails, { headers: { 'Content-Type': 'multipart/form-data' } });
  }
  showDigiLocker() {
    return this.http.get(digiLockerUrl, { headers: this.header });
  }
  preview(customerId: any, docName: any, docType: any) {
    return this.http.get(apiURL.retriveFile(customerId, docName, docType), {});
  }
  pennyTesting(customerId: any, bank_details: any) {
    return this.http.post(apiURL.pennyTest(customerId), bank_details);
  }
  userDetailsrm() {
    return this.http.get(apiURL.userDetails());
  }
  getDigiLockerHTML(cid: any) {
    return this.http.get(apiURL.getDigiLockerHtml(cid), {});
  }

  redirectFromDigiLocker(customerId: string, body: any) {
    return this.http.post(apiURL.digiLockerRedirect(customerId), body);
  }

  completeStatus(customerId: string, body: any) {
    return this.http.post(apiURL.completeStatus(customerId), body);
  }
  nomination(customerId: string, body: any) {
    return this.http.post(apiURL.nomiNee(customerId), body);
  }
  patcNnomination(customerId: string, body: any) {
    return this.http.patch(apiURL.nomiNee(customerId), body);
  }
  updateNomination(customerId: string, nominationId: any, body: any) {
    return this.http.patch(apiURL.updateNomiNee(customerId, nominationId), body);
  }
  deleteNomination(customerId: any, nominationId: any) {
    return this.http.delete(apiURL.updateNomiNee(customerId, nominationId));
  }
  proceedNomination(customerId: any) {
    return this.http.patch(apiURL.proceedNomiNee(customerId), {});
  }

  getLoginToken(payload: any) {
    return this.http.get(apiURL.verifyToken(payload.CUSTOMER_ID) + '?token=' + payload.token);
  }

  postBondMarket(customerId: string, body: any) {
    return this.http.post(apiURL.bondMarket(customerId), body);
  }

  editBondMarket(customerId: string, body: any) {
    return this.http.patch(apiURL.bondMarket(customerId), body);
  }
  saveKycEmai(customerId: string, accHolderId: any, body: any) {
    return this.http.post(apiURL.multiAccKycEmail(customerId, accHolderId), body);
  }

  accountHolderSubmit(id: any) {
    return this.http.post(apiURL.accountHolder(id), {});
  }
  OnUpdatePanDetails(custId: any, data: any) {
    return this.http.patch(apiURL.ModifyPanDetails(custId), data);
  }
  UpdatePanDetails(custId: any, data: any) {
    return this.http.post(apiURL.ModifyPanDetails(custId), data);
  }
}
