import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { breadcrumbConst } from '../../constants/layout.const';
@Component({
  selector: 'app-wealth-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() items: any = breadcrumbConst;
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
