import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  bottomButton: any;
  breadcrumbData: any;
  selectedPage: any = '';
  checkboxEnt: any = {
    declaration: true,
    bseMutual: true,
    age: true,
    market: false,
  };
  hideFooter = false;
  // checkboxKyc: any = {
  //   age: false,
  // };
  checkboxInvestor: any = {
    term_condition: false,
  };
  agedeclaration: boolean = false;
  MarketDeclaration: boolean = false;
  MarketDeclarationValue: boolean = false;
  digiLockerFailed: boolean = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private layoutService: LayoutService, private router: ActivatedRoute) {}

  ngOnInit(): void {
    this.initialiseLayout();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  getDataParameter(page: any) {
    this.selectedPage = page.page || this.router.snapshot.data?.page || '';
    this.hideFooter = page.hideFooter || false;
  }

  btnClicked(btn: any) {
    if (!btn.isDisabled) {
      this.layoutService.setClickEvent(btn);
    }
  }

  initialiseLayout() {
    this.layoutService.routingChange.pipe(takeUntil(this.onDestroy$)).subscribe((res: any) => {
      this.getDataParameter(res);
    });

    this.layoutService.layoutVariableSub.pipe(takeUntil(this.onDestroy$)).subscribe((res: any) => {
      if (res.bottomButton) {
        setTimeout(() => {
          this.bottomButton =
            // window.location.href.split('/')[3] === 'kyc-profiler'
            //   ? [{ label: 'Proceed', key: 'proceed', isDisabled: false, visible: true }]
            //   :
            res.bottomButton;
        });
      }
      if (res.breadcrumb) {
        if (this.selectedPage === 'mobile') {
          res.breadcrumb = [res.breadcrumb[0]];
        } else if (this.selectedPage === 'application') {
          res.breadcrumb = '';
        }
        this.breadcrumbData = res.breadcrumb;
      }
    });
    this.layoutService.AgeDeclarationValue.subscribe((res: boolean) => {
      this.agedeclaration = res;
    });
    this.layoutService.isAgeDeclarationValueset.subscribe((res: boolean) => {
      this.checkboxEnt.age = res;
    });
    this.layoutService.isBseMfSet.subscribe((res: boolean) => {
      this.checkboxEnt.bseMutual = res;
    });
    this.layoutService.marketDeclaration.subscribe((res: boolean) => {
      this.MarketDeclaration = res;
    });
    this.layoutService.marketDeclarationValue.subscribe((res: boolean) => {
      this.checkboxEnt.market = res;
    });
    this.layoutService.DigiLockerFailed.subscribe((res: boolean) => {
      this.digiLockerFailed = res;
    });
  }

  checkBoxEntFn(checked: any) {
    const evt = {
      key: 'checkbox',
      data: this.checkboxEnt,
    };
    this.layoutService.setClickEvent(evt);
  }

  showDialog() {
    const evt = {
      key: 'declaration',
      data: true,
    };
    this.layoutService.setClickEvent(evt);
  }
}
