import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/component/layout/layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OpsLayoutComponent } from './shared/component/ops-layout/ops-layout.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'customer/login',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mobile/mobile.module').then((m) => m.MobileModule),
    data: {
      page: 'mobile',
    },
  },
  {
    path: 'ops-check',
    component: OpsLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/ops/ops.module').then((m) => m.OpsModule),
  },
  {
    path: 'ops-verification',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customerops/customerops.module').then((m) => m.CustomerOpsModule),
    data: {
      fullScreen: true,
    },
  },
  {
    path: 'customer-info',
    component: OpsLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customer/customer.module').then((m) => m.CustomerModule),
    data: {
      opsScreen: true,
    },
  },
  {
    path: 'account',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
    data: {
      page: 'account',
    },
  },
  {
    path: 'caputure-selfie',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/selfie/selfie.module').then((m) => m.SelfieModule),
    data: {
      page: 'mobile',
    },
  },
  {
    path: 'kyc-verification',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kyc/kyc.module').then((m) => m.KycModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'kyc-profiler',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/kyc-profiler/kyc-profiler.module').then((m) => m.KycProfilerModule),
    data: {
      page: 'Profiler',
    },
  },
  {
    path: 'signature',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/signature/signature.module').then((m) => m.SignatureModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'cheque-upload',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/checkupload/checkupload.module').then((m) => m.CheckuploadModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'offer',
    component: OpsLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/offer/offer.module').then((m) => m.OfferModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'message',
    component: OpsLayoutComponent,
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/messages/message.module').then((m) => m.MessageModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'bank',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/bank/bank.module').then((m) => m.BankModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'market',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/market/market.module').then((m) => m.MarketModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'redirect/digilocker',
    component: LayoutComponent,
    loadChildren: () => import('./pages/digilocker/digilocker.module').then((m) => m.DigilockerModule),
    data: {
      page: 'kyc',
    },
  },
  {
    path: 'kyc-fatca',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customerstatus/customers-status.module').then((m) => m.CustomersStatusModule),
    data: {
      page: 'kyc-fatca',
    },
  },
  {
    path: 'nominee',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/nomination/nominee.module').then((m) => m.NomineeModule),
    data: {
      page: 'nominee',
    },
  },
  {
    path: 'personal-details',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customerdetails/customers-details.module').then((m) => m.CustomersDetailsModule),
    data: {
      page: 'personal-details',
    },
  },
  {
    path: 'investor-risk',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/invester/invester.module').then((m) => m.InvesterModule),
    data: {
      page: 'investor-risk',
    },
  },
  {
    path: 'customer/landing',
    loadChildren: () => import('./pages/landing/landing.module').then((m) => m.LandingModule),
    data: {
      page: 'landing-page',
    },
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'applications',
    component: LayoutComponent,
    loadChildren: () => import('./pages/multipleAccounts/multipleAccounts.module').then((m) => m.MultipleAccountsModule),
    data: {
      page: 'application',
    },
  },
  {
    path: '**',
    redirectTo: 'customer/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserModule, BrowserAnimationsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
