export const breadcrumbConst = [
  { label: 'Login', routerLink: '/dashboard', key: 'mobile', active: false },
  { label: 'Account opening information', routerLink: `/create-record`, key: 'account', active: false },
  { label: 'KYC and FATCA Home', routerLink: '/dashboard', key: 'Profiler', active: false },
  { label: 'KYC', routerLink: '/dashboard', key: 'kyc', active: false },
  { label: 'KYC and FATCA', routerLink: '/dashboard', key: 'FATCA', active: false },
  { label: 'Nomination details', routerLink: '/dashboard', key: 'Nomination', active: false },
  { label: 'Secondary bond market', routerLink: '/dashboard', key: 'Secondary_bond', active: false },

  // { label: 'Profiler', routerLink: '/dashboard', key: 'Profiler', active: true },
];

export const bottomButton = [
  { label: 'Save', key: 'save', isDisabled: false, visible: true },
  { label: 'Proceed', key: 'proceed', isDisabled: true, visible: true },
];
